import { Component } from "react"
import { connect } from "react-redux"
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import moment from "moment"
import classNames from "classnames"
import { convertMinutesToHoursMinutes } from "@mobilemind/common/src/functions/index"
import { Link } from "react-router-dom"
import LeaderboardsNavbarContainer from "./LeaderboardsNavbarContainer"
import SidebarLeaderboards from "./SidebarLeaderboards"
import Loading from "@mobilemind/common/src/components/Loading"
import BottomScrollListener from "react-bottom-scroll-listener"
import he from "he"
import { getCategories } from "@mobilemind/common/src/actions/course"
import { getSubGroups } from "../../store/reducers/session"
import {
  getLeaderboard,
  getPartnerLeaderboard,
  updateFilters,
  updatePartnerFilters,
} from "./leaderboardsSlice"

import "../../styles/reports.scss"

import ErrorBoundary from "../../components/ErrorBoundary"

import { resetBackpack } from "../../store/reducers/backpack"
import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"
import SidebarPartnerLeaderboards from "./SidebarPartnerLeaderboards"
import UserLabel from "@mobilemind/common/src/components/UserLabel"
import { useAppDispatch } from "store/hooks"

const mapStateToProps = ({ categories, leaderboards, session }) => {
  return {
    categories,
    leaderboards,
    session,
  }
}

const mapDispatchToProps = {
  getLeaderboard,
  getPartnerLeaderboard,
  getCategories,
  getSubGroups,
}

class LeaderboardsLayout extends Component {
  componentDidMount() {
    const { session, categories } = this.props

    if (!categories.fetched) {
      this.props.getCategories()
    }

    if (this.props.session.isPartner) {
      this.props.getPartnerLeaderboard()
    } else {
      this.props.getLeaderboard()
    }
    if (
      !session.subGroups.fetched &&
      !session.subGroups.fetching &&
      !this.props.session.isPartner
    ) {
      this.props.getSubGroups(session.group.uuid[0].value)
    }
  }

  render() {
    if (this.props.session.subGroups.fetched || this.props.session.isPartner) {
      return (
        <ErrorBoundary>
          <LeaderboardsReport {...this.props} />
        </ErrorBoundary>
      )
    }
    return null
  }
}

function LeaderboardsReport(props) {
  const { filters, partnerFilters, dateOption } = props.leaderboards
  const { field_rewind_begin, field_rewind_end } = props.session.group
  const { isPartner } = props.session

  const dispatch = useAppDispatch()

  const showSchoolYear =
    props.leaderboards.dateOption === "schoolYear" &&
    field_rewind_begin &&
    field_rewind_begin[0] &&
    field_rewind_begin[0].value &&
    field_rewind_end &&
    field_rewind_end[0] &&
    field_rewind_end[0].value

  const { field_open_time, field_close_time } = props.session.group

  function handleScroll() {
    // If we aren't already fetching more results
    if (!props.leaderboards.fetching) {
      // And if there are more pages to fetch
      if (props.leaderboards.currentPage < props.leaderboards.totalPages - 1) {
        if (isPartner) {
          dispatch(updatePartnerFilters({ name: "increaseCurrentPage" }))
        } else {
          dispatch(updateFilters({ name: "increaseCurrentPage" }))
        }
      }
    }
  }

  return (
    <div
      className={classNames(
        "page hasSidebar reports",
        isPartner && "partner-report"
      )}
    >
      <LeaderboardsNavbarContainer />
      {props.session.isPartner ? (
        <SidebarPartnerLeaderboards {...props} />
      ) : (
        <SidebarLeaderboards {...props} />
      )}

      <BottomScrollListener onBottom={handleScroll} />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (isPartner) {
                    dispatch(
                      updatePartnerFilters({
                        name: "sortOrder",
                        value:
                          partnerFilters.sortOrder === "ASC" ? "DESC" : "ASC",
                      })
                    )
                  } else {
                    dispatch(
                      updateFilters({
                        name: "sortOrder",
                        value: filters.sortOrder === "ASC" ? "DESC" : "ASC",
                      })
                    )
                  }
                }}
              >
                <span
                  className={classNames(
                    "icon sort",
                    filters.sortOrder === "ASC" && "flipped"
                  )}
                />

                {dateOption === "dateRange" && (
                  <>
                    <>
                      {moment(filters.dateFrom).isValid() ? (
                        <>{moment(filters.dateFrom).format("M/D/YYYY")} </>
                      ) : (
                        "Beginning of Time "
                      )}{" "}
                      {moment(filters.dateTo).isValid() ? (
                        <>- {moment(filters.dateTo).format("M/D/YYYY")}</>
                      ) : (
                        <>- Today</>
                      )}
                    </>
                  </>
                )}

                {(dateOption === "year" ||
                  dateOption === "month" ||
                  dateOption === "week") && (
                  <>
                    {" "}
                    {moment().startOf(dateOption).format("M/D/YYYY")} -{" "}
                    {moment().format("M/D/YYYY")}
                  </>
                )}
                {showSchoolYear && (
                  <>
                    {moment(field_rewind_begin[0].value).format("M/D/YYYY")} -{" "}
                    {moment(field_rewind_end[0].value).format("M/D/YYYY")}
                  </>
                )}

                {dateOption === "allTime" && <>Beginning of Time - Today</>}
                {filters.schoolHours === "true" && (
                  <span style={{ display: "inline-block", marginLeft: 10 }}>
                    {field_open_time[0].value} - {field_close_time[0].value}
                  </span>
                )}
              </div>
            </TableCell>
            <TableCell align={"right"}>
              <div className="flexRow">
                {!props.leaderboards.fetching && (
                  <>
                    {((filters.leaderboardType === "badges-earned" &&
                      !isPartner) ||
                      (partnerFilters.leaderboardType ===
                        "badges_leaderboard" &&
                        isPartner)) && <>Total Badges Earned</>}

                    {((filters.leaderboardType === "courses-completed" &&
                      !isPartner) ||
                      (partnerFilters.leaderboardType ===
                        "course_leaderboard" &&
                        isPartner)) && <>Total Courses Completed</>}

                    {partnerFilters.leaderboardType === "lp_leaderboard" &&
                      isPartner && <>Total Learning Paths Completed</>}

                    {filters.leaderboardType.includes("time-spent") ? (
                      <>
                        {filters.leaderboardType === "time-spent" && (
                          <strong>Total Time Spent</strong>
                        )}
                        {filters.leaderboardType === "time-spent-course" && (
                          <strong>Total Course Time Spent</strong>
                        )}
                        {filters.leaderboardType === "event-time-spent" && (
                          <strong>Total Event Time Spent</strong>
                        )}
                        {filters.leaderboardType === "ext-event-time-spent" && (
                          <strong>Total External Event Time Spent</strong>
                        )}

                        <span className="total">
                          <TimeCapsule
                            hours={
                              convertMinutesToHoursMinutes(
                                props.leaderboards.total
                              ).hours
                            }
                            minutes={
                              convertMinutesToHoursMinutes(
                                props.leaderboards.total
                              ).minutes
                            }
                          />
                        </span>
                      </>
                    ) : (
                      <span className="total">
                        {props.leaderboards.total &&
                          props.leaderboards.total.toLocaleString()}
                      </span>
                    )}

                    {((filters.leaderboardType === "badges-earned" &&
                      !isPartner) ||
                      (isPartner &&
                        partnerFilters.leaderboardType ===
                          "badges_leaderboard")) && (
                      <span className="icon badge" />
                    )}

                    {((filters.leaderboardType === "courses-completed" &&
                      !isPartner) ||
                      (isPartner &&
                        partnerFilters.leaderboardType ===
                          "course_leaderboard")) && (
                      <span className="icon check" />
                    )}

                    {isPartner &&
                      partnerFilters.leaderboardType === "lp_leaderboard" && (
                        <span className="icon learningPath" />
                      )}
                  </>
                )}
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.leaderboards.data &&
            props.leaderboards.data.length > 0 &&
            props.leaderboards.data.map((user) => {
              let imageSrc =
                user.user_picture &&
                process.env.REACT_APP_API_URL + user.user_picture

              const leftContent = !isPartner && (
                <UserLabel
                  firstName={he.decode(user.field_first_name)}
                  lastName={he.decode(user.field_last_name)}
                  imagePath={imageSrc}
                  subgroups={user.field_subgroup}
                />
              )

              const rightContent = (
                <>
                  <div className="items-completed">
                    {!isPartner && (
                      <>
                        {filters.leaderboardType === "badges-earned" && (
                          <>
                            <>{user.field_badge}</>
                            <span className="icon badge" />
                          </>
                        )}
                        {filters.leaderboardType === "courses-completed" && (
                          <>
                            <>{user.id}</>
                            <span className="icon check" />
                          </>
                        )}
                        {filters.leaderboardType.includes("time-spent") && (
                          <>
                            {filters.leaderboardType === "time-spent" && (
                              <TimeCapsule
                                hours={
                                  convertMinutesToHoursMinutes(
                                    Number(user.total_time_spent)
                                  ).hours
                                }
                                minutes={
                                  convertMinutesToHoursMinutes(
                                    Number(user.total_time_spent)
                                  ).minutes
                                }
                              />
                            )}
                            {filters.leaderboardType ===
                              "time-spent-course" && (
                              <TimeCapsule
                                hours={
                                  convertMinutesToHoursMinutes(
                                    Number(user.assessment_time_spent)
                                  ).hours
                                }
                                minutes={
                                  convertMinutesToHoursMinutes(
                                    Number(user.assessment_time_spent)
                                  ).minutes
                                }
                              />
                            )}
                            {filters.leaderboardType === "event-time-spent" && (
                              <TimeCapsule
                                hours={
                                  convertMinutesToHoursMinutes(
                                    Number(user.event_time_spent)
                                  ).hours
                                }
                                minutes={
                                  convertMinutesToHoursMinutes(
                                    Number(user.event_time_spent)
                                  ).minutes
                                }
                              />
                            )}
                            {filters.leaderboardType ===
                              "ext-event-time-spent" && (
                              <TimeCapsule
                                hours={
                                  convertMinutesToHoursMinutes(
                                    Number(user.ext_usr_event_time_spent)
                                  ).hours
                                }
                                minutes={
                                  convertMinutesToHoursMinutes(
                                    Number(user.ext_usr_event_time_spent)
                                  ).minutes
                                }
                              />
                            )}
                          </>
                        )}
                      </>
                    )}

                    {isPartner && (
                      <>
                        {partnerFilters.leaderboardType ===
                          "course_leaderboard" && (
                          <>
                            <>{user.completed_count}</>
                            <span className="icon check" />
                          </>
                        )}

                        {partnerFilters.leaderboardType ===
                          "badges_leaderboard" && (
                          <>
                            <>{user.earned_count}</>
                            <span className="icon badge" />
                          </>
                        )}
                        {partnerFilters.leaderboardType ===
                          "lp_leaderboard" && (
                          <>
                            <>{user.completed_count}</>
                            <span className="icon learningPath" />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              )
              // Partner Leaderboard
              if (isPartner) {
                return (
                  <TableRow key={user.uid}>
                    <TableCell
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <Accordion style={{ flex: 1 }}>
                        <AccordionSummary>
                          <div className="partnerInfo" style={{ flex: 1 }}>
                            <div className="icon district" />
                            <strong>{user.label}</strong>
                            {user.subgroups.length > 0 && (
                              <span className="icon caret" />
                            )}
                          </div>
                          <>{rightContent}</>
                        </AccordionSummary>
                        {user.subgroups.length > 0 && (
                          <AccordionDetails>
                            <ul
                              style={{
                                listStyleType: "none",
                                padding: 0,
                                marginTop: -5,
                                marginLeft: -10,
                                marginRight: -10,
                                marginBottom: 0,
                              }}
                            >
                              {user.subgroups.map((subgroup, index) => {
                                return (
                                  <li
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: index ? 10 : 0,
                                      padding: 10,
                                      paddingLeft: 45,
                                      paddingRight: 45,
                                      borderTop: "1px solid #ebebeb",
                                    }}
                                  >
                                    <strong style={{ flex: 1 }}>
                                      <span className="icon school" />
                                      {subgroup.label}
                                    </strong>
                                    <span className="count">
                                      {(props.leaderboards.partnerFilters
                                        .leaderboardType ===
                                        "course_leaderboard" ||
                                        props.leaderboards.partnerFilters
                                          .leaderboardType ===
                                          "lp_leaderboard") &&
                                        subgroup.completed_count}
                                      {props.leaderboards.partnerFilters
                                        .leaderboardType ===
                                        "badges_leaderboard" &&
                                        subgroup.earned_count}
                                      {props.leaderboards.partnerFilters
                                        .leaderboardType === "lp_leaderboard" &&
                                        subgroup.earned_count}
                                    </span>
                                  </li>
                                )
                              })}
                            </ul>
                          </AccordionDetails>
                        )}
                      </Accordion>
                    </TableCell>
                  </TableRow>
                )
              }

              return (
                <TableRow key={user.uid}>
                  <TableCell>
                    <div className="flexRow">
                      <div className="userInfo flexRow">
                        {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          <Link
                            onClick={() => {
                              dispatch(resetBackpack())
                            }}
                            to={"/backpack/" + user.uid}
                          >
                            {leftContent}
                          </Link>
                        }
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align={"right"}>
                    <Link to={"/backpack/" + user.uid}>{rightContent}</Link>
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>

      {props.leaderboards.fetching && (
        <Loading
          message={
            props.leaderboards.currentPage > 0
              ? "Getting more results..."
              : "Getting results..."
          }
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardsLayout)
